"use strict";

var MOBBEW = MOBBEW || (function() {
    var _args = {};
    
    return {
        init : function(Args) {
            _args = Args;
        },               
        sendEmailContact: function() {
            var params = {
                email: $('#email').val(),
                name: $('#name').val(),
                phone: $('#phone').val(),
                message: $('#message').val(),
                //host: window.location.origin
            };

            if (params.email == '') {
                alert('O campo e-mail é obrigatório...');
                return;
            }
            if (params.name == '') {
                alert('O campo nome é obrigatório...');
                return;
            }
            if (params.message == '') {
                alert('O campo mensagem é obrigatório...');
                return;
            }

            var success = function(response) {
                this.hideLoad();
                alert(response.message);                
                
                if (response.error) {
                    $('#email').prop('disabled', 'false');
                    $('#email').val('');
                    $('#name').prop('disabled', 'false');
                    $('#name').val('');
                    $('#phone').prop('disabled', 'false');
                    $('#phone').val('');
                    $('#message').prop('disabled', 'false');
                    $('#message').val('');
                } else {                   
                    window.location.href = 'index.php';
                }
            };

            $('#email').prop('disabled', 'true');
            $('#name').prop('disabled', 'true');
            $('#phone').prop('disabled', 'true');
            $('#city').prop('disabled', 'true');
            $('#message').prop('disabled', 'true');
            
            this.showLoad('Enviando Email...');
            this.httpReq('recovery_mail_contact', 'POST', params, success);
            //window.location.href = 'contato.php';
        },
        
        /*
         * #####################################################################
         * ######################### LOADER FUNCTIONS ##########################
         */
        showLoad: function(msg) {
            if (msg === null || msg === undefined || msg === '') {
                msg = 'Carregando...';
            }
            
            var loader = '<div id="request-loader" style="position: fixed;top: 0;left: 0;width: 100%;height: 100%;background: rgba(0, 0, 0, 0.7);z-index: 1;"><div style="position: relative;background: #FFF;max-width: 300px;height: 70px;padding: 24px 0;text-align: center;border-radius: 4px;left: 50%;margin-left: -150px;top: 50%;margin-top: -35px;font-size: 15px;"><i class="fa fa-fw fa-spinner fa-spin"></i> ' + msg + '</div></div>';
            $('body').css("overflow", "hidden");
            $('body').append(loader);
        },

        hideLoad: function() {
            $('body').css("overflow", "auto");
            $('#request-loader').remove();
        },
               
        /*
         * #####################################################################
         * ######################### HELPERS FUNCTIONS #########################
         */
        httpReq: function(_action, _type, _params, _callback) {
            $.ajax({
                url      : '/mobbew_utils/incoming_calls_ajax.php',
                data     : {action: _action, data: _params},
                type     : _type,
                dataType : 'json',
                context  : this,
                success  : _callback
            });
        }
    };
}());

$(document).ready(function() {
    $('#back-to-top').fadeOut();
    
    $(window).on("scroll", function() {
        if ($(this).scrollTop() > 250) {
            $('#back-to-top').fadeIn(1400);
        } else {
            $('#back-to-top').fadeOut(400);
        }
    });

    $('#top').on('click', function() {
        $('top').tooltip('hide');
        $('body,html').animate({
            scrollTop: 0
        }, 800);
        return false;
    });

    $('.navbar-nav li a').on('click', function(e) {
        var anchor = $(this);
        $('html, body').stop().animate({
            scrollTop: $(anchor.attr('href')).offset().top - 0
        }, 1500);
        e.preventDefault();
    });
    $(window).on('scroll', function() {
        if ($(this).scrollTop() > 100) {
            $('header').addClass('menu-sticky');
        } else {
            $('header').removeClass('menu-sticky');
        }
    });

    $('#iq-amazing-tab').on('click', 'a', function() {
        $('#iq-amazing-tab  li a.active1').removeClass('active1');
        $(this).addClass('active1');
    });

    $('.iq-progress-bar > span').each(function() {
        var $this = $(this);
        var width = $(this).data('percent');
        $this.css({
            'transition': 'width 2s'
        });
        setTimeout(function() {
            $this.appear(function() {
                $this.css('width', width + '%');
            });
        }, 500);
    });
});